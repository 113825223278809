import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Card from "react-bootstrap/Card"
import { Button, Badge } from "react-bootstrap"

export default props => {
  const buttonInternal =
    <Button style={{gridArea: "action1"}} variant="outline-info" as={Link} to={props.to}>
      Read More...
    </Button>

  const buttonExternal =
    <>
      <Button style={{gridArea: "action1"}} target="_blank" variant="outline-info" as={Link} to={props.repo}>
        Explore Code
      </Button>
      <Button style={{gridArea: "action2"}} target="_blank" variant="outline-primary" as={Link} to={props.link}>
        Visit Project
      </Button>
    </>

  const cardButton = props.link === null
    ? buttonInternal
    : buttonExternal

  return (
    <Card className="card-container single-project" style={{maxWidth: "100%"}}>
      <Card.Img as={Img} fluid={props.featuredImage} className="h-50" />
      <Card.Body className="pt-3">
        <Card.Title>
          <h4>{props.title}</h4>
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {props.subtitle}
        </Card.Subtitle>
        {props.tags.map(tag => (
          <Badge key={tag} pill variant="dark" className="px-2 mr-1">
            {tag}
          </Badge>
        ))}
        <Card.Text style={{marginTop: "7px"}}>{props.excerpt}</Card.Text>
      </Card.Body>
      <div style={{
        padding: "20px",
        display: "grid",
        gap: "20px",
        gridTemplateAreas: `"action1 action2"`,
        gridTemplateColumns: "1fr 1fr"
      }}>
        {cardButton}
      </div>
    </Card>
  )
}
