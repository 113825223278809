import React from "react"
import { Container } from "react-bootstrap"
import Header from "./Header"
import Footer from "./Footer"
import NewHero from "./NewHero"
import Skill from "./Skill"

export default ({ children, data }) => (
  <Container fluid className="px-0 app-container">
    <Header />
    <NewHero />
    <Skill direction = "left" theme = "blue" data={data}/>
    <Footer />
  </Container>
)
