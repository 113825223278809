import React, { useContext } from "react"
import ThemeContext from "../utils/theme"
import { Container, Image, Row, Col, Button } from "react-bootstrap"
import { Parallax } from 'react-scroll-parallax';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"

export default ({direction, theme, reverse, data}) => {
  const cardStyle = cardStyles(direction, theme)
  const styledWrapper = wrapperStyles(direction, theme)
  const { dark } = useContext(ThemeContext)

  const background = dark ? "#222222ba" : "#ffffff4a"

  console.log(data)

  const rowDirection = !!reverse
    ? {flexDirection: "row-reverse"}
    : {}

  let rowStyles = {
    background: "transparent",
    height: "100%",
    padding: "75px 0"
  }

  let titleColStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: background,
    padding: "20px",
    backdropFilter: "blur(4px)"
  }

  let colors = {
    dev: {
      col: "#005d8b80",
      text: "#263238",
      button: "#263238"
    },
    open: {
      col: "#009e9080",
      text: "#004d40",
      button: "#004d40"
    },
    articles: {
      col: "#001aa880",
      text: "#1a237e",
      button: "#1a237e"
    },
    conf: {
      col: "#cddc394a",
      text: "#827717",
      button: "#827717"
    }
  }

  return (
    <Container style={styledWrapper} className="text-center" fluid>
      {hexBackground(theme, dark)}
      {home_section({
        title: "Projects",
        color: "#005d8b",
        cta: "Projects",
        extended: true,
        data: data.projects.edges.filter(x => x.node.frontmatter.tags.includes("Project")),
        logo: "../../assets/icons/blacksmith_craft.PNG",
        intro: <>
          <p>
            Hi! I’m Pedro, a software developer with over 10 years of experience in the field. In that time I have worked in many positions, including backend, frontend, fullstack web developer, and project leader.
          </p>
          <p>
            Over the years I have also worked with many programming languages and technologies including C#, Java, PHP, Javascript and many others.
          </p>
          <p>
            In recent years I have switched to focus on R and Shiny, taking part in delivering dozens of projects using those technologies.
          </p>
        </>
      })}
      {home_section({
        title: "Open Source",
        color: "#004d40",
        cta: "Projects",
        extended: true,
        data: data.projects.edges.filter(x => x.node.frontmatter.tags.includes("Package")),
        logo: "../../assets/icons/tailoring.png",
        intro: <>
          <p>
            As an Open Source enthusiast I have also been active in open source for a few years, specially when it comes to packages and showcase applications for R and Shiny.
          </p>
          <p>
            Given my extensive past work on web development, and its overlap with the R/Shiny world, I also author and release my own R and Shiny packages, trying to bring some of that web development experience into the Shiny world when possive.
          </p>
        </>
      })}
      {home_section({
        title: "Articles",
        color: "#1a237e",
        cta: "Articles and Presentations",
        extended: false,
        data: data.articles.edges.filter(x => !x.node.frontmatter.tags.includes("Video")),
        logo: "../../assets/icons/diplomacy.PNG",
        intro: <>
          <p>
            When possible I also do my best to ocasionally write about my experiences, projects, and findings, in a way that can be useful and educational to anyone interested.
          </p>
          <p>
            You can find some of my tutorials, projects and tool showcasings in diferent media platforms including <a href="https://medium.com/@pedrocoutinhosilva" rel="noreferrer" target="_blank">Medium</a>, <a href="https://rviews.rstudio.com/" rel="noreferrer" target="_blank">RViews</a>, and <a href="https://appsilon.com/blog/" rel="noreferrer" target="_blank">Appsilon</a>.
          </p>
        </>
      })}
      {home_section({
        title: "Presentations",
        color: "#bf7c32",
        cta: "Articles and Presentations",
        extended: false,
        data: data.articles.edges.filter(x => x.node.frontmatter.tags.includes("Video")),
        logo: "../../assets/icons/alchemy.png",
        intro: <>
          <p>
            I have also taken part a few presentations, workshops and webinars in diferent conferences and events.
          </p>
          <p>
            Recently you could find me in rconf:2020 and rconf:global organized by RStudio, as well as in some online workshops and webinnars, usually revolving around the topics of R/Shiny, UI/UX or Web Development.
          </p>
        </>
      })}
    </Container>
  )
}

const home_section = (options) => {
  let cta_link = options.cta == "Projects"
    ? "projects"
    : "articles"

  return(
    <div className="home-section-wrapper row-background" style={{background: `${options.color}05`}}>
      <Container className="skill-wrapper card-container">
        <Container className="py-0 my-0" style={{background: "transparent"}}>
          <Row>
            <Col className="skill-card-header" xs={12} sm={12} md={12} lg={12}>
             <img src={options.logo}
                  style={{filter: `drop-shadow(4px 4px 6px ${options.color}99)`}}
                  alt={options.title}
                  className="skill-card-icon"
                  height="120px"/>

             <div className="skill-name-wrapper" style={{background: `${options.color}`}}>
               <h1 className="first">{options.title}</h1>
             </div>
            </Col>
            <Col className="skill-card-wrapper" xs={0} sm={0} md={0} style={{background: "transparent"}}></Col>
            <Col className="skill-card-content skill-card-intro" xs={12} sm={12} md={12} lg={12} xl={7}>
              <div className="intro-wrapper">
                {options.intro}
              </div>
            </Col>
            <Col className="skill-card-content skill-card-recent" xs={12} sm={12} md={12} lg={12} xl={5}>
              <div className="recent-wrapper">
                <h3>{`Recent ${options.title}`}</h3>
                <div className="recent-links" style={{borderTop: `1px solid ${options.color}`}}>
                  {
                    options.data
                      .slice(0, 3)
                      .map((node) => {
                        return <>
                        <a style={{margin: "10px 0"}} className="px-1" rel="noreferrer" target="_blank" href={node.node.frontmatter.link}>
                          {node.node.frontmatter.title}
                        </a>
                        {options.extended ? node.node.excerpt : ""}
                        </>
                      })
                  }
                </div>
                <Button style={{background: options.color, border: "1px solid rgba(255, 255, 255, 0.18)"}} variant="primary" as={Link} to={cta_link}>{`More ${options.cta}...`}</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  )
}



const single = (options) => {
  let contentColStyles = {
    display: "flex",
    marginTop: "15px",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    textAlign: "justify",
    width: "100%"
  }


  let titleStyles = {
    textAlign: "left",
    color: options.colStyle.color,
    letterSpacing: "2px"
  }

  let logoStyles = {
    filter: `drop-shadow(4px 4px 6px ${options.colStyle.background})`
  }

 return(
   <div className="row-background" style={{
     background: `${options.colStyle.color}15`,
     position: "relative"
   }}>
     <Container style={options.cardStyle} className="card-container">
       <Container className="py-0 my-0" style={{background: "transparent"}}>
         <Row style={options.rowStyle}>
           <Col className="skill-card-header" style={{background: "transparent"}} xs={12} sm={12} md={12} lg={12}>

            <img src={options.sectionLogo} style={logoStyles} alt="logo" className="skill-card-icon" height="120px"/>
            <div className="skill-name-wrapper" style={{...options.colStyle, height: "100%"}}>
              <h1 className="first" style={{...titleStyles, width: "100%", textAlign: "center"}}>
                {options.title}
              </h1>
            </div>
           </Col>
           <Col xs={0} sm={0} md={0} style={{background: "transparent"}}></Col>
           <Col className="skill-card-content skill-card-intro" style={contentColStyles} xs={12} sm={12} md={12} lg={7}>
             {options.content}
           </Col>
           <Col className="skill-card-content skill-card-recent" style={contentColStyles} xs={12} sm={12} md={12} lg={5}>
             {options.content_two}
           </Col>
         </Row>
       </Container>
     </Container>
   </div>
 )
}

const hexBackground = (theme, dark) => {
  return(
    <div
      style={{
        height: "100%", width: "100%", background: "transparent",
        position: "absolute", top: "0", opacity: ".7"
      }}>
      <Parallax
        className="parallax-background" y={[-20, 20]} tagOuter="figure">
        <div style={backgroundParallaxStyle(theme, dark)}>
          <Image src="/assets/shiny.svg" style={{width: "30%", opacity: "0.1", position: "absolute", left: "5%", top: "30%"}}/>
          <Image src="/assets/dplyr.svg" style={{width: "30%", opacity: "0.1", position: "absolute", right: "5%", top: "15%"}}/>
          <Image src="/assets/sass.svg" style={{width: "30%", opacity: "0.1", position: "absolute", left: "10%", bottom: "5%"}}/>
          <Image src="/assets/shiny.svg" style={{width: "20%", opacity: "0.1", position: "absolute", right: "10%", bottom: "20%"}}/>
          <Image src="/assets/pipe.svg" style={{width: "16%", opacity: "0.1", position: "absolute", right: "44%", bottom: "44%"}}/>
          <Image src="/assets/devtools.svg" style={{width: "18%", opacity: "0.1", position: "absolute", left: "5%", top: "8%"}}/>
          <Image src="/assets/sass.svg" style={{width: "20%", opacity: "0.1", position: "absolute", right: "35%", top: "15%"}}/>
          <Image src="/assets/rsconnect.svg" style={{width: "10%", opacity: "0.1", position: "absolute", left: "28%", top: "20%"}}/>
          <Image src="/assets/pkgdown.svg" style={{width: "15%", opacity: "0.1", position: "absolute", right: "6%", top: "46%"}}/>
          <Image src="/assets/R6.svg" style={{width: "12%", opacity: "0.1", position: "absolute", right: "25%", top: "35%"}}/>
        </div>
      </Parallax>
    </div>
  )
}

const themes = {
  blue: {
    base: "#E7FDFF",
    darken: "#D3E7EC",
    lighter: "#D3E7EC",
    shadow: "#496268"
  },
  green: {
    base: "#CBEF90",
    darken: "#94B75B",
    lighter: "#CBEF90",
    shadow: "#5F8229"
  },
  orange: {
    base: "#ffd3b4",
    darken: "#ffd3b4",
    lighter: "#ffd3b4",
    shadow: "#ffd3b4"
  },
  red: {
    base: "#ffaaa7",
    darken: "#ffaaa7",
    lighter: "#ffaaa7",
    shadow: "#ffaaa7"
  }
}

let backgroundParallaxStyle = (theme, dark) => {
  let base = dark ? "#222" : "#ffffff"
  let highlight = dark ? themes[theme].shadow : themes[theme].base

  let style = {
    width: "100%",
    height: "100%",
    position: "relative",
    background: `linear-gradient(180deg, ${base} 20%, ${highlight} 60%, ${base} 80%)`
  }

  return(style)
}

let cardStyles = (direction, theme) => {
  let styles = {
    height: "auto",
    background: "transparent",
    borderRadius: "0 20px 20px 0",
    zIndex: "1",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    width: "100%",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  }


  if (direction !== "left") {
    styles.borderRadius = "20px 0 0 20px"
    styles.left = null
    styles.right = "0"
    styles.boxShadow = `-3px 3px 2px 1px ${themes[theme].shadow}`
  }

  return (styles)
}

let wrapperStyles = (direction, theme) => {
  return({
    overflow: "hidden",
    position: "relative",
    padding: "0"
  })
}
