import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Container, Badge, Col, Row } from "react-bootstrap"

export default ({ html, excerpt, featuredImages, tags, title, to, link }) => {
  let type = tags.includes("Video")
    ? "../../assets/icons/alchemy.png"
    : "../../assets/icons/diplomacy.PNG"

  return (
    <Container className="text-center articles-wrapper" style={{display: "flex"}}>
      <div className="entry-type-wrapper" style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "30px"}}>
        <img src={type}
             className="entry-type"
             height="120px"/>
      </div>
      <div style={{paddingRight: "20px"}}>
        <Link target="_blank" to={link} style={{ textDecoration: "none" }}>

          <h2 className="mt-5" style={{textAlign: "left", paddingBottom: "15px", marginTop: "15px"}}>{title}</h2>
        </Link>
        <div className="pt-3 text-justify" dangerouslySetInnerHTML={{__html: html}}>
        </div>
        <div className="pt-3 text-justify">
          {tags.map(tag => (
            <Badge key={tag} pill variant="dark" className="px-2 mr-1">
              {tag}
            </Badge>
          ))}
        </div>
      </div>
    </Container>
  )
}
