import React from "react"
import { graphql } from "gatsby"
import { Container } from "react-bootstrap"
import { useStaticQuery } from "gatsby"
import BlobBackground from "./BlobBackground"
import HexBackground from "./hexBackground"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Image, Row, Col, Button } from "react-bootstrap"

export default () => {
  const { firstName, lastName, social } = useStaticQuery(query).site.siteMetadata
  return (
    <div className= "new-hero-wrapper">
      <div className="overlay"/>
      <HexBackground/>
      <div className="new-hero-details-wrapper">
        <div className="new-hero-social-wrapper">
          <a
            href={social.github}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={["fab", "github"]}
              className="icons github"
              title="Github"
            />
          </a>
          <a
            href={social.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={["fab", "twitter"]}
              className="icons file"
              title="Twitter"
            />
          </a>
          <a
            href={social.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={["fab", "linkedin"]}
              className="icons linkedin"
              title="LinkedIn"
            />
          </a>
          <a
            href={`mailto:${social.mail}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={["fas", "envelope"]}
              className="icons mail"
              title="e-mail"
            />
          </a>
        </div>
        <div className="new-hero-name-wrapper">
          <div className="first-name-cell">
            <h1>
              <span className="first-name">{firstName}</span>
            </h1>
          </div>
          <div className="last-name-cell">
            <h1>
              <span className="last-name">{lastName}</span>
            </h1>
          </div>
        </div>
        <div className="new-hero-tagline-wrapper">
          <i>
            Full stack and R/Shiny developer, practitioner of JavaScript dark arts
          </i>
        </div>
      </div>
    </div>
  )
}
const query = graphql`
  query {
    site {
      siteMetadata {
        firstName
        lastName
        social {linkedin, github, mail, twitter}
      }
    }
  }
`

const styledHero = {
  height: "calc(60vh - 56px)",
  position: "relative",
  padding: "0",
  zIndex: "1",
  boxShadow: "0px 5px 7px 0 rgba(0, 0, 0, 0.37)"
}

const styledDetails = {
  position: "absolute",
  top: "0",
  bottom: "0",
  left: "0",
  right: "0",
  margin: "auto",
  height: "80%",
  borderRadius: "20px !important",
  overflow: "hidden",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  background: "transparent"
}
